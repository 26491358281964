$(document).ready(function() {
	$('#slider-container').slick({
		dots: false,
		autoplay: true,
		pauseOnHover: true,
		speed: 1600,
		prevArrow: false,
		nextArrow: false
	});

	$('.mobile-cover-types').slick({
		dots: true,
		infinite: false,
		speed: 1000,
		variableWidth: true,
		prevArrow: false,
		nextArrow: false,
		initialSlide: 0,
		slidesToShow: 2,

		responsive: [
			{
				breakpoint: 700,
				settings: {
					slidesToShow: 1,
					//initialSlide: 1,
					infinite: false,
					dots: true
				}
			}
		]
	});

});